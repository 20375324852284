import { Menu } from '@headlessui/react'
import React, { MouseEventHandler } from 'react'

export interface ActionMenuItemProps {
  onClick: MouseEventHandler<HTMLButtonElement>
  label: string | JSX.Element
  dataTestAttributes?: Record<string, string>
}

/**
 * @deprecated
 */
export const ActionMenuItem = ({ dataTestAttributes, onClick, label }: ActionMenuItemProps) => (
  <Menu.Item key={'action-settings'}>
    {({ active }) => (
      <button
        onClick={onClick}
        aria-pressed={active}
        type="button"
        className={`group aria-pressed:text-blue flex items-center gap-2 w-full px-4 py-3 lg:py-2 text-left text-sm main-transition-colors border-b border-gray-200 last:border-0`}
        {...dataTestAttributes}
      >
        <span>{label}</span>
      </button>
    )}
  </Menu.Item>
)
