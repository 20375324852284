import { LocaleEnum } from 'shared/enums/locale-enum'
import httpClient from '../utils/http-client'

export function changeLanguage(locale: LocaleEnum) {
  return httpClient.post('/api/dashboard/user/change-language', { language: locale })
}

interface UserDomainAccountInterface {
  displayName: string
  profileImage: string
}

export const getUserDomainAccount = () => {
  return httpClient.get<UserDomainAccountInterface>('/api/dashboard/user/user-domain-account')
}

interface UserProfileInfoInterface {
  firstName: string
  lastName: string
  phone: string
}

export const getUserProfileInfo = () => {
  return httpClient.get<UserProfileInfoInterface>('/api/dashboard/user/profile-information')
}
