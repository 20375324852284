import { useDashboardUser } from './hooks/use-dashboard-user'
import { usePublicUser } from './hooks/use-public-user'
import { useUserStatus } from './hooks/use-user-status'

const useUser = () => {
  const { publicUser, publicMutate } = usePublicUser()
  const { dashboardUser, dashboardMutate, isDisabled } = useDashboardUser()

  const user = !isDisabled ? dashboardUser : undefined

  const {
    userStatus,
    isWsAdmin,
    checkAdminRoles,
    checkImpersonatorAdminRoles,
    isWorkspaceOwner,
    isAffiliate,
    isCustomer,
    isStudent,
    isWsAssistant,
    isRootAdmin,
    intlLocale,
  } = useUserStatus(user)

  return {
    // to prevent further fetching if blocked
    user,
    publicUser,
    // for header only
    dashboardUser,
    mutate: dashboardMutate,
    mutatePublic: publicMutate,
    isCustomer,
    isAffiliate,
    isStudent,
    checkAdminRoles,
    checkImpersonatorAdminRoles,
    isWorkspaceOwner,
    isWsAdmin,
    isWsAssistant,
    userStatus,
    isLoading: !dashboardUser && !publicUser,
    isDisabled,
    isRootAdmin,
    intlLocale,
  }
}

export default useUser
